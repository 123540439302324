.catalogue {
    padding: 1rem 1rem;
    padding-bottom: 2rem;
    background-color: #f0f4f8;
    font-family: 'Arial', sans-serif;
}

/* General Packages Styles */
.packages {
    background: linear-gradient(to right, #bf4377, #0a1f34);
    color: white;
    padding: 1rem;
    /* border-radius: 10px; */
    margin-bottom: 2rem;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.packages h2 {
    margin-bottom: .5rem;
    font-size: 1.8rem;
    font-weight: bold;
}

.packages ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    list-style-type: none;
    padding: 0;
}

.packages li {
    /* margin-bottom: 0.5rem; */
    font-size: 1.2rem;
    text-align: left;
    padding: 0.5rem;
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* border-radius: 5px; */
}


.limited-text {
    display: -webkit-box;            /* Enables the box model needed for line clamping */
    -webkit-box-orient: vertical;    /* Sets the box's orientation to vertical */
    -webkit-line-clamp: 2;           /* Limits the text to 2 lines */
    overflow: hidden;                /* Hides any overflowing text */
    text-overflow: ellipsis;         /* Adds ellipsis (…) to indicate clipped text */
    line-height: 1.5;                /* Adjust the line height as needed */
    max-height: 3em;                 /* Sets a max height based on the line height and line clamp */
}

/* Boat List Styles */
.boat-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.boat {
    /* background-color: #0a1f34; */
    color: white;
    padding: 1rem;
    /* border-radius: 10px; */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.boat:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}


.boat img {
    width: 100%;
    height: auto;
    /* border-radius: 10px; */
    transition: transform 0.3s ease;
}

.boat:hover img {
    transform: scale(1.05);
}

.linkUnderline {
   text-decoration: none;
}

.details {
    margin-top: 12px;
    text-align: center;
}

.details h3 {
    font-family: "romain_reguRgText", "Times", "serif";
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.89;
    letter-spacing: 0.36px;
    text-align: center;
    color: #121526;
    text-transform: uppercase;
    text-decoration-line: none;
    text-decoration: none;
}

.details p {
    /* font-size: 1rem;
    margin-bottom: 1rem; */
    
    font-family: "futura-pt", sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: normal;
    color: #929292;

}

/* Options Styles */
.options {
    display: flex;
    gap: 1rem;
    /* justify-content: center; */
    margin-top: 1rem;
}

.options button {
    background-color: #bf4377;
    color: white;
    border: none;
    padding: 0.5rem .5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

.options button:hover {
    background-color: #8e2f5c;
}

/* Modal Styles */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #f1f1f1;
    padding: 2rem;
    border-radius: 5px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    text-align: center;
}

.close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #bf4377;
}

.close:hover {
    color: #8e2f5c;
}

.modal-content h3 {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content label {
    margin: 0.5rem 0;
    font-size: 1rem;
}

.modal-content input {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
    font-size: 1rem;
}

.modal-content button {
    background-color: #bf4377;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    /* border-radius: 5px; */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

.modal-content button:hover {
    background-color: #8e2f5c;
}
