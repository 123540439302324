@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Open+Sans:wght@400;600&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.sorted-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.event-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    display: flex;
    flex-direction: column;
}

.event-image {
    width: 100%;
    /* height: 200px; */
    object-fit: cover;
    text-decoration: none;

}

.event-info {
    padding: 1rem;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;

}

.event-info h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #222;
}

.event-info .price {
    font-size: 1.2rem;
    font-weight: 600;
    color: #bf4377;
    margin-bottom: 1rem;
}

.event-info p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.event-info .slogan {
    margin: 1rem 0;
    font-size: 0.9rem;
    line-height: 1.7;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.event-info-details {
    display: flex;
    color: black;
}

.features {
    margin-top: 1rem;
}

.features h3 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #222;
    border-bottom: 2px solid #bf4377;
    display: inline-block;
    padding-bottom: 0.2rem;
}

.features ul {
    padding: 0;
    list-style-type: none;
}

.features li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #444;
    padding-left: 1rem;
    position: relative;
}

.features li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #bf4377;
    font-size: 1.5rem;
    line-height: 1;
}

.book-now {
    background-color: #bf4377;
    color: white;
    border: none;
    padding: 5px 35px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    /* margin-top: 10px; */
    transition: background-color 0.3s ease;
    align-self: center;
    text-decoration-line: none;
}

@media (max-width: 768px) {
    .sorted-events {
        padding: 1rem;
    }

    .event-card {
        width: 90vh;
    }
}