/* Footer.css */

.footer-container {
    background-color: #0a1f34;
    color: #ffffff;
    padding: 2rem 1rem;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 1.5rem;
}

.footer-section {
    flex: 1 1 250px;
    margin: 1rem 0;
}

.footer-section h2 {
    color: #bb4377;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.footer-section p {
    font-size: 1rem;
    line-height: 1.6;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin: 0.5rem 0;
}

.footer-section ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #bb4377;
}

.footer-social-icons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.footer-social-icons a {
    margin: 0 0.5rem;
    color: #ffffff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.footer-social-icons a:hover {
    color: #bb4377;
}

.footer-bottom {
    margin-top: 2rem;
    border-top: 1px solid #bb4377;
    padding-top: 1rem;
    font-size: 0.9rem;
}

.footer-bottom-tanzator-image {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-bottom-tanzator-image img {
    filter: invert(34%) sepia(35%) saturate(5462%) hue-rotate(313deg) brightness(90%) contrast(96%);
}

.footer-bottom-tanzator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-bottom a {
    color: #bb4377;
    text-decoration: none;
    gap: 3px;
}

.footer-bottom a:hover {
    text-decoration: none;
    filter: invert(100%);

}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
}