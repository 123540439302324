.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.container {
  width: 100%;
}

.topnav {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.logo img {
  height: 4rem;
  width: 4rem;
}

.bars {
  display: none; /* Initially hidden */
  cursor: pointer;
  color: white;
  margin-left: auto;
}

.menu {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.menu.open {
  display: block; /* Display when open */
}

.link {
  color: white;
  padding: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link .icon {
  margin-right: 0.5rem;
}

.link_text {
  font-size: 1rem;
  /* font-family: "myriadBold"; */
}

.active {
  background-color: #555;
}

.sign_out {
  color: red;
  margin-left: auto;
  cursor: pointer;
}



.center-placeholder {
  position: fixed; /* Ensures the div covers the entire screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically center the image */
  background-color: white; /* White background */
  z-index: 999; /* Ensures it is on top of other content */
}

.center-placeholder img {
  opacity: 0.3; /* Adjust this for transparency */
  max-width: 50%; /* Make sure the logo fits well */
  max-height: 50%; /* Adjust size to fit well */
}


@media (max-width: 768px) {
  .menu {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 5rem;
      left: 0;
      background-color: #333;
  }

  .bars {
      display: block;
  }

  .link {
      justify-content: center;
  }
}

.spin-container {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw;   */

  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Aligns to the top */
  height: 100vh;           /* Full viewport height */
  width: 95vw;            /* Full viewport width */
  padding-top: 10vh;       /* Pushes the spinner down 20% from the top */
}

.ant-spin-dot-item {
  background-color: #bf4377 !important;
}






.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.progressive-image-container {
  position: relative;
  width: 100%;
}





.no-image-spinner-container {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 10; */
  /* width: 100%; */
  height: 100%;
}



::-webkit-scrollbar {display:none;} 
