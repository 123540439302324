/* Topbars.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.all-content-container{
    background-color: #f0f4f8;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.topnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    /* White background */
    padding: 0.5rem 1rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Shadow */
}



.logo img {
    height: 3rem;
    width: auto;
    display: flex;

}

.titleSmall {
flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5em;
}

.titleLarge {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
}

.bars {
    cursor: pointer;
    display: flex;
    font-size: 2.5rem;
    color: #0a1f34;
    /* Dark blue color */
    margin-right: 1rem;
    /* Adjust spacing between icon and "YCC" */
}

.ycc {
    text-align: center;
    color: #0a1f34;
    /* Dark blue color */
    font-size: 1.5rem;
    margin-right: 1rem;
    /* Adjust spacing between "YCC" and logo */
}

.menu {
    display: none;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    /* Center items in dropdown */
    background-color: #ffffff;
    /* White background */
    padding: 1rem;
    position: absolute;
    top: 100%;
    /* Position below topnav */
    right: 0;
    z-index: 1001;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    /* Full width */
}

.menu.open {
    display: flex;
align-items: normal;
}

.link {
    color: #0a1f34;
    /* Dark blue color for menu links */
    text-decoration: none;
    font-family: 'Arial', sans-serif;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: x-large;

}

.link:hover,
.link.active {
    background-color: #bf4377;
    /* Dark pink background on hover/active */
    color: #ffffff;
    font-size: x-large;
    /* White text color on hover/active */
}

.logout {
    cursor: pointer;
    color: #0a1f34;
    /* Dark blue color */
    font-size: 1rem;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.logout:hover {
    color: #bf4377;
    /* Dark pink color on hover */
}

.logoLargeScreen {
    display: none;
}

.logoSmallScreen {
    flex: 0 0 auto;
}

/* Large screen styles */
@media (min-width: 769px) {

    .topnav {
        /* display: flex; */
        /* align-items: center; */
        /* justify-content: space-evenly; */
        background-color: #ffffff;
        /* White background */
        /* Shadow */
    }

    .titleSmall {
        display: none;
    }

    .titleLarge {
        display: flex;
        flex:auto;
    }

    .ycc {
        /* margin-right: auto; */
        text-align: left;


        /* Push YCC to the left */
    }

    .bars {
        display: none;
        order: -1;
        /* Shift bars to the beginning */
    }

    .menu {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        /* align-items: flex-end; */
        /* Align items horizontally */
        /* background-color: #11ffff; */
        /* White background */
        padding: 0.5rem;
        justify-content: flex-end;
        position: static;
        transform: none;
        box-shadow: none;
        flex: 1;


    }

    .menu.open {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        /* align-items: flex-end; */
        /* Align items horizontally */
        /* background-color: #11ffff; */
        /* White background */
        padding: 0.5rem;
        justify-content: flex-end;
        position: static;
        transform: none;
        box-shadow: none;
        flex: auto;

    }

    .link {
        color: #0a1f34;
        /* Dark blue color for menu links */
        text-decoration: none;
        font-family: 'Arial', sans-serif;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        flex-direction: row;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .link:hover {
        background-color: #0a1f34;
        /* Dark pink background on hover/active */
        color: #ffffff;
        /* White text color on hover/active */
        font-size: 1rem;

    }

    .link.active {
        background-color: #bf4377;
        /* Dark pink background on hover/active */
        color: #ffffff;
        font-size: 1rem;
        /* White text color on hover/active */
    }

    .logout {
        cursor: pointer;
        color: #0a1f34;
        /* Dark blue color */
        font-size: 1rem;
        padding: 0.5rem 1rem;
        transition: color 0.3s ease;
    }

    .logout:hover {
        color: #bf4377;
        /* Dark pink color on hover */
    }

    .logoLargeScreen {
        display: flex;
        /* flex: auto; */
        margin-right: 50px;
        /* background-color: chartreuse; */

    }

    .logoSmallScreen {
        display: none;
    }
}